import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";
import Pagination from "@mui/material/Pagination";

import Stack from "@mui/material/Stack";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { formatDate } from "utils";
import Grid from "@mui/material/Unstable_Grid2";
import { getApplications } from "apis/applications";

export default function Applications() {
  const color = "light";
  const [reg, setReg] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [payload, setPayload] = useState({
    page: 0,
    mobile: "",
    imei1: "",
    imei2: "",
    size: 25,
  });

  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const handleGetApplications = async (filters) => {
    try {
      const { data } = await getApplications(filters);

      setReg(data.data);
      setTotalPages(data.pages);
    } catch (err) {
      toast(err.response?.status + " " + err?.response?.data?.message, {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }
  };

  useEffect(() => {
    (async () => {
      const obj = {
        ...payload,
        mobile: payload.mobile.length === 10 ? payload.mobile : null,
        imei1: payload.imei1.length === 15 ? payload.imei1 : null,
        imei2: payload.imei2.length === 15 ? payload.imei2 : null,
      };
      await handleGetApplications(obj);
    })();
  }, [payload]);

  const handleEmiDetailsClick = (customer) => {
    setSelectedCustomer(customer);
    setIsModalOpen(true);
  };

  const handlePageChange = (event, value) => {
    setPayload((prev) => ({
      ...prev,
      page: value - 1, // Pagination component is 1-based, API is 0-based
    }));
  };

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }>
        <div className="block w-full overflow-x-auto p-8">
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <FormControl sx={{ width: "350px" }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Search by Mobile Number
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    label="Search by Mobile Number"
                    aria-describedby="outlined-weight-helper-text"
                    onChange={(e) => {
                      setPayload({
                        ...payload,
                        mobile: e.target.value,
                      });
                    }}
                    value={"" + payload.mobile}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl sx={{ width: "350px" }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Search by IMEI 1
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    label="Search by Mobile Number"
                    aria-describedby="outlined-weight-helper-text"
                    onChange={(e) => {
                      setPayload({
                        ...payload,
                        imei1: e.target.value,
                      });
                    }}
                    value={"" + payload.imei1}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl sx={{ width: "350px" }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Search by IMEI 2
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    label="Search by Mobile Number"
                    aria-describedby="outlined-weight-helper-text"
                    onChange={(e) => {
                      setPayload({
                        ...payload,
                        imei2: e.target.value,
                      });
                    }}
                    value={"" + payload.imei2}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </div>
        <div className="flex justify-center my-4">
          <Stack spacing={2}>
            <Pagination
              className={"text-white"}
              count={totalPages}
              page={payload.page + 1}
              onChange={handlePageChange}
              color="primary"
            />
          </Stack>
        </div>{" "}
        <div className="block w-full overflow-x-auto p-8">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="" />
                <TableHeader heading="Name" />
                <TableHeader heading="Status" />
                <TableHeader heading="Locked" />
                <TableHeader heading="Mobile" />
                <TableHeader heading="IMEIS" />
                <TableHeader heading="Retailer" />
                <TableHeader heading="Created On" />
                <TableHeader heading="" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {reg.map((item, i) => {
                return (
                  <tr
                    key={i}
                    className="divide-x divide-gray-200 hover:bg-slate-100">
                    <TableElement value={i + 1} />

                    <TableElement
                      value={
                        <span className="font-semibold">
                          {item?.customerDetails?.name}
                        </span>
                      }
                      type="element"
                    />
                    <TableElement value={item?.deviceData?.status} />
                    <TableElement
                      value={
                        item?.deviceData?.locked ? (
                          <p className="text-red-500">YES</p>
                        ) : (
                          <p className="text-green-500">NO</p>
                        )
                      }
                    />
                    <TableElement value={item?.customerDetails?.mobile} />
                    <TableElement
                      value={
                        <span>
                          {item?.customerDetails?.imei1} <br />
                          {item?.customerDetails?.imei2}
                        </span>
                      }
                    />
                    <TableElement
                      value={
                        <span>
                          {item?.user?.name} <br />
                          {item?.user?.mobile}
                        </span>
                      }
                    />

                    <TableElement value={item?.createdOn} />
                    <TableElement
                      value={
                        <Button onClick={() => handleEmiDetailsClick(item)}>
                          View More
                        </Button>
                      }
                    />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {/* <Modal
        isOpen={isModalOpen}
        customer={selectedCustomer}
        onClose={() => setIsModalOpen(false)}
      /> */}

      <RegistrationDetails
        isOpen={isModalOpen}
        customer={selectedCustomer}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}

const RegistrationDetails = ({ isOpen, onClose, customer }) => {
  return (
    <Dialog maxWidth="2xl" fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>Registration Details</DialogTitle>
      <DialogContent dividers>
        <h1 className="mt-4 ">Customer's Details</h1>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-200">
            <tr className="divide-x divide-gray-400">
              <TableHeader heading="Name" />
              <TableHeader heading="Father's Name" />
              <TableHeader heading="Grandfather's Name" />
              <TableHeader heading="Email" />
              <TableHeader heading="Mobile" />
              <TableHeader heading="Phone" />
              <TableHeader heading="Alternate Mobile" />
              <TableHeader heading="Current Address" />
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr className="divide-x divide-gray-200 hover:bg-slate-100">
              <TableElement value={customer?.customerDetails?.name} />
              <TableElement value={customer?.customerDetails?.fatherName} />
              <TableElement
                value={customer?.customerDetails?.grandFatherName}
              />
              <TableElement value={customer?.customerDetails?.email} />
              <TableElement value={customer?.customerDetails?.mobile} />
              <TableElement value={customer?.customerDetails?.phone} />
              <TableElement
                value={customer?.customerDetails?.alternateMobile}
              />
              <TableElement value={customer?.customerDetails?.currentAddress} />
            </tr>
          </tbody>
        </table>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-200">
            <tr className="divide-x divide-gray-400">
              <TableHeader heading="Marital Status" />
              <TableHeader heading="Citizenship Number" />
              <TableHeader heading="PAN" />
              <TableHeader heading="PAN Issued Date" />
              <TableHeader heading="Citizenship Issued Date" />
              <TableHeader heading="Citizenship Issued District" />
              <TableHeader heading="Date of Birth" />
              <TableHeader heading="Registration Date" />
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr className="divide-x divide-gray-200 hover:bg-slate-100">
              <TableElement value={customer?.customerDetails?.maritalStatus} />
              <TableElement
                value={customer?.customerDetails?.citizenshipNumber}
              />
              <TableElement value={customer?.customerDetails?.pan} />
              <TableElement value={customer?.customerDetails?.panIssuedDate} />
              <TableElement
                value={customer?.customerDetails?.citizenshipIssuedDate}
              />
              <TableElement
                value={customer?.customerDetails?.citizenshipIssuedDistrict}
              />
              <TableElement value={customer?.customerDetails?.dateOfBirth} />
              <TableElement
                value={customer?.customerDetails?.registrationDate}
              />
            </tr>
          </tbody>
        </table>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-200">
            <tr className="divide-x divide-gray-400">
              <TableHeader heading="Retailer Name" />
              <TableHeader heading="Retailer Address" />
              <TableHeader heading="Income Type" />
              <TableHeader heading="Memo Number" />
              <TableHeader heading="Remark" />
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr className="divide-x divide-gray-200 hover:bg-slate-100">
              <TableElement value={customer?.customerDetails?.retailerName} />
              <TableElement
                value={customer?.customerDetails?.retailerAddress}
              />
              <TableElement value={customer?.customerDetails?.incomeType} />
              <TableElement value={customer?.customerDetails?.memoNumber} />
              <TableElement value={customer?.customerDetails?.remark} />
            </tr>
          </tbody>
        </table>

        <h1 className="mt-8 ">Guarantor's Details</h1>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-200">
            <tr className="divide-x divide-gray-400">
              <TableHeader heading="Name" />
              <TableHeader heading="Father's Name" />
              <TableHeader heading="Grandfather's Name" />
              <TableHeader heading="Marital Status" />
              <TableHeader heading="Email" />
              <TableHeader heading="Mobile" />
              <TableHeader heading="Phone" />
              <TableHeader heading="Citizenship Number" />
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr className="divide-x divide-gray-200 hover:bg-slate-100">
              <TableElement value={customer?.guarantorDetails?.name} />
              <TableElement value={customer?.guarantorDetails?.fatherName} />
              <TableElement
                value={customer?.guarantorDetails?.grandFatherName}
              />
              <TableElement value={customer?.guarantorDetails?.maritalStatus} />
              <TableElement value={customer?.guarantorDetails?.email} />
              <TableElement value={customer?.guarantorDetails?.mobile} />
              <TableElement value={customer?.guarantorDetails?.phone} />
              <TableElement
                value={customer?.guarantorDetails?.citizenshipNumber}
              />
            </tr>
          </tbody>
        </table>

        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-200">
            <tr className="divide-x divide-gray-400">
              <TableHeader heading="PAN" />
              <TableHeader heading="PAN Issued Date" />
              <TableHeader heading="Citizenship Issued Date" />
              <TableHeader heading="Citizenship Issued District" />
              <TableHeader heading="Date of Birth" />
              <TableHeader heading="Address" />
              <TableHeader heading="Nature of Relationship" />
              <TableHeader heading="Occupation" />
              <TableHeader heading="Remark" />
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr className="divide-x divide-gray-200 hover:bg-slate-100">
              <TableElement value={customer?.guarantorDetails?.pan} />
              <TableElement value={customer?.guarantorDetails?.panIssuedDate} />
              <TableElement
                value={customer?.guarantorDetails?.citizenshipIssuedDate}
              />
              <TableElement
                value={customer?.guarantorDetails?.citizenshipIssuedDistrict}
              />
              <TableElement value={customer?.guarantorDetails?.dateOfBirth} />
              <TableElement value={customer?.guarantorDetails?.address} />
              <TableElement
                value={customer?.guarantorDetails?.natureOfRelationship}
              />
              <TableElement value={customer?.guarantorDetails?.occupation} />
              <TableElement value={customer?.guarantorDetails?.remark} />
            </tr>
          </tbody>
        </table>

        <h1 className="mt-8 ">Loan Details</h1>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-200">
            <tr className="divide-x divide-gray-400">
              <TableHeader heading="Cost Price" />
              <TableHeader heading="Financing" />
              <TableHeader heading="Loan Amount" />
              <TableHeader heading="Rate of Interest" />
              <TableHeader heading="Tenure" />
              <TableHeader heading="EMI Amount" />
              <TableHeader heading="Down Payment" />
              <TableHeader heading="Total Amount" />
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr className="divide-x divide-gray-200 hover:bg-slate-100">
              <TableElement value={customer?.loanDetails?.costPrice ?? "-"} />
              <TableElement value={customer?.loanDetails?.financing ?? "-"} />
              <TableElement value={customer?.loanDetails?.loanAmount ?? "-"} />
              <TableElement
                value={customer?.loanDetails?.rateOfIntrest ?? "-"}
              />
              <TableElement value={customer?.loanDetails?.tenure ?? "-"} />
              <TableElement value={customer?.loanDetails?.emiAmount ?? "-"} />
              <TableElement value={customer?.loanDetails?.downPayment ?? "-"} />
              <TableElement value={customer?.loanDetails?.totalAmount ?? "-"} />
            </tr>
          </tbody>
        </table>

        <table className="min-w-full divide-y divide-gray-200 mt-4">
          <thead className="bg-gray-200">
            <tr className="divide-x divide-gray-400">
              <TableHeader heading="Receipt Number" />
              <TableHeader heading="Security Coverage" />
              <TableHeader heading="Monthly Income" />
              <TableHeader heading="Monthly Expense" />
              <TableHeader heading="Surplus" />
              <TableHeader heading="Income Coverage" />
              <TableHeader heading="Commission" />
              <TableHeader heading="DO Amount" />
              <TableHeader heading="Total EMI Amount" />
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr className="divide-x divide-gray-200 hover:bg-slate-100">
              <TableElement
                value={customer?.loanDetails?.receiptNumber ?? "-"}
              />
              <TableElement
                value={customer?.loanDetails?.securityCoverage ?? "-"}
              />
              <TableElement
                value={customer?.loanDetails?.monthlyIncome ?? "-"}
              />
              <TableElement
                value={customer?.loanDetails?.monthlyExpense ?? "-"}
              />
              <TableElement value={customer?.loanDetails?.surplus ?? "-"} />
              <TableElement
                value={customer?.loanDetails?.incomeCoverage ?? "-"}
              />
              <TableElement value={customer?.loanDetails?.commission ?? "-"} />
              <TableElement value={customer?.loanDetails?.doAmount ?? "-"} />
              <TableElement
                value={customer?.loanDetails?.totalEMIAmount ?? "-"}
              />
            </tr>
          </tbody>
        </table>
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-200">
            <tr className="divide-x divide-gray-200 ">
              <TableHeader heading="EMI DATE" />
              <TableHeader heading="STATUS" />
              <TableHeader heading="AMOUNT" />
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {customer?.loanDetails?.emiDueDates?.map((item, i) => (
              <tr
                key={i}
                className="divide-x divide-gray-200 hover:bg-slate-100">
                <TableElement value={formatDate(item?.emiDate)} />
                <TableElement
                  value={
                    item?.status === "PAID" ? (
                      <p className="text-green-500">PAID</p>
                    ) : (
                      <p className="text-red-500">DUE</p>
                    )
                  }
                />

                <TableElement value={" " + customer?.loanDetails?.emiAmount} />
              </tr>
            ))}
          </tbody>
        </table>

        <h1 className="mt-8 ">Documents Details</h1>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-200">
            <tr className="divide-x divide-gray-400">
              <TableHeader heading="Citizenship Front" />
              <TableHeader heading="Citizenship Back" />
              <TableHeader heading="Photo" />
              <TableHeader heading="Income" />
              <TableHeader heading="Guarantor Citizenship Front" />
              <TableHeader heading="Guarantor Citizenship Back" />
              <TableHeader heading="Guarantor Photo" />
              <TableHeader heading="Quotation" />
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200 bg-white">
            <tr className="divide-x divide-gray-200 hover:bg-slate-100">
              <TableElement
                value={
                  customer?.documentsDetails?.citizenshipFront ? (
                    <a
                      rel="noopener noreferrer"
                      href={customer?.documentsDetails?.citizenshipFront}
                      target="_blank">
                      <img
                        src={customer?.documentsDetails?.citizenshipFront}
                        alt="citizenship-front"
                        loading="lazy"
                        className="h-20 bg-white border-4"
                      />
                    </a>
                  ) : (
                    "-"
                  )
                }
              />
              <TableElement
                value={
                  customer?.documentsDetails?.citizenshipBack ? (
                    <a
                      rel="noopener noreferrer"
                      href={customer?.documentsDetails?.citizenshipBack}
                      target="_blank">
                      <img
                        src={customer?.documentsDetails?.citizenshipBack}
                        alt="citizenship-back"
                        loading="lazy"
                        className="h-20 bg-white border-4"
                      />
                    </a>
                  ) : (
                    "-"
                  )
                }
              />
              <TableElement
                value={
                  customer?.documentsDetails?.photo ? (
                    <a
                      rel="noopener noreferrer"
                      href={customer?.documentsDetails?.photo}
                      target="_blank">
                      <img
                        src={customer?.documentsDetails?.photo}
                        alt="customer"
                        loading="lazy"
                        className="h-20 bg-white border-4"
                      />
                    </a>
                  ) : (
                    "-"
                  )
                }
              />
              <TableElement
                value={
                  customer?.documentsDetails?.income ? (
                    <a
                      rel="noopener noreferrer"
                      href={customer?.documentsDetails?.income}
                      target="_blank">
                      <img
                        src={customer?.documentsDetails?.income}
                        alt="income"
                        loading="lazy"
                        className="h-20 bg-white border-4"
                      />
                    </a>
                  ) : (
                    "-"
                  )
                }
              />
              <TableElement
                value={
                  customer?.documentsDetails?.guarantorCitizenshipFront ? (
                    <a
                      rel="noopener noreferrer"
                      href={
                        customer?.documentsDetails?.guarantorCitizenshipFront
                      }
                      target="_blank">
                      <img
                        src={
                          customer?.documentsDetails?.guarantorCitizenshipFront
                        }
                        alt="guarantor-citizenship-front"
                        loading="lazy"
                        className="h-20 bg-white border-4"
                      />
                    </a>
                  ) : (
                    "-"
                  )
                }
              />
              <TableElement
                value={
                  customer?.documentsDetails?.guarantorCitizenshipBack ? (
                    <a
                      rel="noopener noreferrer"
                      href={
                        customer?.documentsDetails?.guarantorCitizenshipBack
                      }
                      target="_blank">
                      <img
                        src={
                          customer?.documentsDetails?.guarantorCitizenshipBack
                        }
                        alt="guarantor-citizenship-back"
                        loading="lazy"
                        className="h-20 bg-white border-4"
                      />
                    </a>
                  ) : (
                    "-"
                  )
                }
              />
              <TableElement
                value={
                  customer?.documentsDetails?.guarantorPhoto ? (
                    <a
                      rel="noopener noreferrer"
                      href={customer?.documentsDetails?.guarantorPhoto}
                      target="_blank">
                      <img
                        src={customer?.documentsDetails?.guarantorPhoto}
                        alt="guarantor"
                        loading="lazy"
                        className="h-20 bg-white border-4"
                      />
                    </a>
                  ) : (
                    "-"
                  )
                }
              />
              <TableElement
                value={
                  customer?.documentsDetails?.quotation ? (
                    <a
                      rel="noopener noreferrer"
                      href={customer?.documentsDetails?.quotation}
                      target="_blank">
                      <img
                        src={customer?.documentsDetails?.quotation}
                        alt="quotation"
                        loading="lazy"
                        className="h-20 bg-white border-4"
                      />
                    </a>
                  ) : (
                    "-"
                  )
                }
              />
            </tr>
          </tbody>
        </table>

        <table className="min-w-full divide-y divide-gray-200 ">
          <thead className="bg-gray-200">
            <tr className="divide-x divide-gray-400">
              <TableHeader heading="VAT Bill" />
              <TableHeader heading="Cash" />
              <TableHeader heading="Receipt" />
              <TableHeader heading="Signature" />
              <TableHeader heading="Other 1" />
              <TableHeader heading="Other 2" />
              <TableHeader heading="Other 3" />
              <TableHeader heading="Other 4" />
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200 bg-white">
            <tr className="divide-x divide-gray-200 hover:bg-slate-100">
              <TableElement
                value={
                  customer?.documentsDetails?.vatBill ? (
                    <a
                      rel="noopener noreferrer"
                      href={customer?.documentsDetails?.vatBill}
                      target="_blank">
                      <img
                        src={customer?.documentsDetails?.vatBill}
                        alt="vat-bill"
                        loading="lazy"
                        className="h-20 bg-white border-4"
                      />
                    </a>
                  ) : (
                    "-"
                  )
                }
              />
              <TableElement
                value={
                  customer?.documentsDetails?.cash ? (
                    <a
                      rel="noopener noreferrer"
                      href={customer?.documentsDetails?.cash}
                      target="_blank">
                      <img
                        src={customer?.documentsDetails?.cash}
                        alt="cash"
                        loading="lazy"
                        className="h-20 bg-white border-4"
                      />
                    </a>
                  ) : (
                    "-"
                  )
                }
              />
              <TableElement
                value={
                  customer?.documentsDetails?.receipt ? (
                    <a
                      rel="noopener noreferrer"
                      href={customer?.documentsDetails?.receipt}
                      target="_blank">
                      <img
                        src={customer?.documentsDetails?.receipt}
                        alt="receipt"
                        loading="lazy"
                        className="h-20 bg-white border-4"
                      />
                    </a>
                  ) : (
                    "-"
                  )
                }
              />
              <TableElement
                value={
                  customer?.documentsDetails?.signatureUrl ? (
                    <a
                      rel="noopener noreferrer"
                      href={customer?.documentsDetails?.signatureUrl}
                      target="_blank">
                      <img
                        src={customer?.documentsDetails?.signatureUrl}
                        alt="signature"
                        loading="lazy"
                        className="h-20 bg-white border-4"
                      />
                    </a>
                  ) : (
                    "-"
                  )
                }
              />
              <TableElement
                value={
                  customer?.documentsDetails?.other1 ? (
                    <a
                      rel="noopener noreferrer"
                      href={customer?.documentsDetails?.other1}
                      target="_blank">
                      <img
                        src={customer?.documentsDetails?.other1}
                        alt="other1"
                        loading="lazy"
                        className="h-20 bg-white border-4"
                      />
                    </a>
                  ) : (
                    "-"
                  )
                }
              />
              <TableElement
                value={
                  customer?.documentsDetails?.other2 ? (
                    <a
                      rel="noopener noreferrer"
                      href={customer?.documentsDetails?.other2}
                      target="_blank">
                      <img
                        src={customer?.documentsDetails?.other2}
                        alt="other2"
                        loading="lazy"
                        className="h-20 bg-white border-4"
                      />
                    </a>
                  ) : (
                    "-"
                  )
                }
              />
              <TableElement
                value={
                  customer?.documentsDetails?.other3 ? (
                    <a
                      rel="noopener noreferrer"
                      href={customer?.documentsDetails?.other3}
                      target="_blank">
                      <img
                        src={customer?.documentsDetails?.other3}
                        alt="other3"
                        loading="lazy"
                        className="h-20 bg-white border-4"
                      />
                    </a>
                  ) : (
                    "-"
                  )
                }
              />
              <TableElement
                value={
                  customer?.documentsDetails?.other4 ? (
                    <a
                      rel="noopener noreferrer"
                      href={customer?.documentsDetails?.other4}
                      target="_blank">
                      <img
                        src={customer?.documentsDetails?.other4}
                        alt="other3"
                        loading="lazy"
                        className="h-20 bg-white border-4"
                      />
                    </a>
                  ) : (
                    "-"
                  )
                }
              />
            </tr>
          </tbody>
        </table>

        <h1 className="mt-8 ">Device Details</h1>
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-200">
            <tr className="divide-x divide-gray-200">
              <TableHeader heading="Device ID" />
              <TableHeader heading="Camera Blocked" />
              <TableHeader heading="FCM Token" />
              <TableHeader heading="Brand" />
              <TableHeader heading="Model" />
              <TableHeader heading="Model Name" />
              <TableHeader heading="Modal Color" />
              <TableHeader heading="SIM 1" />
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200 bg-white">
            <tr className="divide-x divide-gray-200 hover:bg-slate-100">
              <TableElement value={customer?.deviceData?.deviceId ?? "-"} />
              <TableElement
                value={customer?.deviceData?.cameraBlocked ? "Yes" : "No"}
              />
              <TableElement value={customer?.deviceData?.fcmToken ?? "-"} />
              <TableElement value={customer?.deviceData?.brand ?? "-"} />
              <TableElement value={customer?.deviceData?.model ?? "-"} />
              <TableElement value={customer?.deviceData?.modelName ?? "-"} />
              <TableElement value={customer?.deviceData?.modalColor ?? "-"} />
              <TableElement value={customer?.deviceData?.sim1 ?? "-"} />
            </tr>
          </tbody>
        </table>

        <table className="min-w-full divide-y divide-gray-300 ">
          <thead className="bg-gray-200">
            <tr className="divide-x divide-gray-200">
              <TableHeader heading="SIM 2" />
              <TableHeader heading="IMEI 1" />
              <TableHeader heading="IMEI 2" />
              <TableHeader heading="Status" />
              <TableHeader heading="Locked" />
              <TableHeader heading="Message" />
              <TableHeader heading="Serial Number" />
              <TableHeader heading="Location" />
              <TableHeader heading="Location Updated on" />
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200 bg-white">
            <tr className="divide-x divide-gray-200 hover:bg-slate-100">
              <TableElement value={customer?.deviceData?.sim2 ?? "-"} />
              <TableElement value={customer?.deviceData?.imei1 ?? "-"} />
              <TableElement value={customer?.deviceData?.imei2 ?? "-"} />
              <TableElement value={customer?.deviceData?.status ?? "-"} />
              <TableElement
                value={
                  customer?.deviceData?.locked ? (
                    <p className="text-red-500">YES</p>
                  ) : (
                    <p className="text-green-500">NO</p>
                  )
                }
              />
              <TableElement value={customer?.deviceData?.message ?? "-"} />
              <TableElement value={customer?.deviceData?.serialNumber ?? "-"} />
              <TableElement
                value={
                  customer?.deviceData?.latitude &&
                  customer?.deviceData?.longitude ? (
                    <a
                      rel="noopener noreferrer"
                      href={`https://maps.google.com/?q=${customer.deviceData.latitude},${customer.deviceData.longitude}`}
                      target="_blank">
                      VIEW
                    </a>
                  ) : (
                    "-"
                  )
                }
              />
              <TableElement
                value={customer?.deviceData?.locationUpdatedOn ?? "-"}
              />
            </tr>
          </tbody>
        </table>
      </DialogContent>
      {/* <DialogActions> */}
      {/* <Button onClick={generateInvoice}>Generate</Button> */}
      {/* </DialogActions> */}
    </Dialog>
  );
};
