import axios from "axios";
import { MERO_EMI } from "./constants";
import { getHeaders } from "./head";

export const getAddressByPin = (pincode) => {
  return axios.get(`${MERO_EMI}/master/address/` + pincode, {
    headers: getHeaders(true),
  });
};

export const uploadFile = (data, type) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${MERO_EMI}/master/upload?type=` + type,
    headers: {
      ...getHeaders(true),
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };

  return axios.request(config);
};
