import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";
import { getAllOrders, updateOrderStatus } from "apis/orders";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { formatDateTime } from "utils";

export default function Transfer() {
  const color = "light";
  const [orders, setOrders] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [message, setMessage] = useState("");
  const [payload, setPayload] = useState({
    status: "COMPLETED",
    page: 0,
    size: 25,
  });

  // State for modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState(null); // 'cancel' or 'confirm'
  const [selectedOrder, setSelectedOrder] = useState(null); // The order that will be acted upon

  useEffect(() => {
    getOrders();
  }, [payload]);

  const getOrders = () => {
    getAllOrders()
      .then((res) => {
        setOrders(res.data.data);
        setTotalPages(Math.ceil(res.data.totalElements / payload.size));
      })
      .catch((err) => {
        const errorMessage =
          err?.response?.data?.message || "Error fetching orders";
        toast(errorMessage, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      });
  };

  const handleUpdateOrder = async (id, status) => {
    try {
      const res = await updateOrderStatus(id, status);
      setOrders(res.data.data); // Update the orders with the new data
      setTotalPages(Math.ceil(res.data.totalElements / payload.size));

      toast(`Order ${status}`, {
        type: "success",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Error updating order";
      toast(errorMessage, {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }
    getOrders(); // Refresh the orders after update
  };

  const handlePageChange = (event, value) => {
    setPayload((prev) => ({
      ...prev,
      page: value - 1, // Pagination component is 1-based, API is 0-based
    }));
  };

  const handleStatusChange = (event) => {
    setPayload((prev) => ({
      ...prev,
      status: event.target.value,
    }));
  };

  const openModal = (action, order) => {
    setModalAction(action);
    setSelectedOrder(order);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedOrder(null);
  };

  const handleConfirmAction = () => {
    if (modalAction === "confirm") {
      handleUpdateOrder(selectedOrder.id, "COMPLETED");
    } else if (modalAction === "cancel") {
      handleUpdateOrder(selectedOrder.id, "PAYMENT_FAILED");
    }
    closeModal();
  };

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="flex justify-center my-4">
          <Stack spacing={2}>
            <Pagination
              className={"text-white"}
              count={totalPages}
              page={payload.page + 1}
              onChange={handlePageChange}
              color="primary"
            />
          </Stack>
        </div>
        <div className="flex justify-end mx-2 my-4">
          <FormControl variant="outlined" className="w-60">
            <InputLabel>Status</InputLabel>
            <Select
              value={payload.status}
              onChange={handleStatusChange}
              label="Status"
            >
              <MenuItem value="PAYMENT_REQUIRED">Payment required</MenuItem>
              <MenuItem value="PAYMENT_FAILED">Payment failed</MenuItem>
              <MenuItem value="COMPLETED">Completed</MenuItem>
              <MenuItem value="CANCELLED">Cancelled</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="block w-full overflow-x-auto p-8">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="S no." />
                <TableHeader heading="Image" />
                <TableHeader heading="Details" />
                <TableHeader heading="Status" />
                <TableHeader heading="" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {orders.length > 0 ? (
                orders.map((item, i) => (
                  <tr
                    key={i}
                    className="divide-x divide-gray-200 hover:bg-slate-100"
                  >
                    <TableElement value={i + 1} />
                    <TableElement
                      value={
                        <img
                          src={item?.imageUrl}
                          height="100px"
                          className="h-20 bg-white border-4"
                        />
                      }
                    />
                    <TableElement
                      value={
                        <>
                          <h2> Name :- {item.user.name} </h2>
                          <h2> Mobile :- {item.user.mobile} </h2>
                          <h2>
                            {item.schemeKey
                              ? `${item.key} + ${item.schemeKey}`
                              : `${item.key}`}{" "}
                            keys at ₹{item.amount}
                          </h2>
                          <h2> UTR :- {item?.tnxId || "NO UTR "}</h2>
                        </>
                      }
                    />
                    <TableElement
                      value={
                        <>
                          <h2>{item.status?.replaceAll("_", " ")}</h2>
                          <h2>{formatDateTime(item.createdOn)}</h2>
                        </>
                      }
                    />
                    <TableElement
                      value={
                        <div className="flex flex-col items-center">
                          <button
                            className="bg-red-500 w-[70%] text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() => {
                              setMessage(
                                "Have you check the payment correctly?\nIt's failed right? "
                              );
                              openModal("cancel", item);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className="bg-green-500 w-[70%] text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() => {
                              setMessage(
                                `You are about to transfer ${item.key} key to ${item?.user?.name}\nHave you check the payment correctly?\nIt's completed right?`
                              );
                              openModal("confirm", item);
                            }}
                          >
                            Confirm
                          </button>
                        </div>
                      }
                    />
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center py-4">
                    No orders available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Confirmation Modal */}
      <Dialog
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">
          {modalAction === "cancel" ? "Cancel Order" : "Confirm Order"}
        </DialogTitle>
        <DialogContent>
          <p>{message}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmAction} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
