import axios from "axios";
import { MERO_EMI } from "./constants";
import { getHeaders } from "./head";

export async function getAllUsers(payload) {
  return await axios.get(`${MERO_EMI}/users`, {
    params: {
      page: payload.page,
      size: payload.size,
      state: payload.state,
    },
    headers: getHeaders(true),
  });
}
