import { base_url, sub_urls } from "../api-urls";
import axios from "axios";
import { MERO_EMI } from "./constants";
import { getHeaders } from "./head";
export const LoginApi = {
  GenerateToken: async (payload) => {
    const response = await axios.post(
      base_url + sub_urls.onboarding.getToken,
      {},
      {
        params: {
          mobile: payload.mobile,
          password: payload.password,
        },
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  GetMasterTOTP: async () => {
    const response = await axios.post(
      base_url + sub_urls.onboarding.getTOTP,
      {},
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
};

export const loginUser = async (payload) => {
  return axios.post(`${MERO_EMI}/onboard/admin/login`, payload, {
    headers: getHeaders(false),
  });
};
export const validateToken = async (payload) => {
  return axios.post(`${MERO_EMI}/onboard/validate`, payload, {
    headers: getHeaders(true),
  });
};
